<template>
  <Dialog>
    <ChangePasswordForm
      v-if="!success"
      @success="() => (success = true)"
      @click-forgot="openForgot"
    />
    <SuccessView
      v-else
      headline="changePassword.success.headline"
      sub-headline="changePassword.success.subHeadline"
      :texts="['changePassword.success.text']"
      image="@/assets/images/minilu/minilu-shield-success.svg"
      cta="changePassword.success.cta"
      @click-cta="() => dialogStore.closeDialog()"
    />
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from '@/components/dialog/components/dialogFrame/global/dialogFrame.vue';
import ChangePasswordForm from '~/components/form/changePassword/minilu/changePasswordForm.vue';
import { DialogIdent, useDialogStore } from '~/stores/useDialogStore';
import SuccessView from '~/components/formFields/components/successView/minilu/successView.vue';
import { useSessionStore } from '~/stores/useSessionStore';

const dialogStore = useDialogStore();
dialogStore.setHeadline('changePassword.headline');
const session = useSessionStore();

if (!session.isLoggedIn) dialogStore.openDialog(DialogIdent.LOGIN);

const success = ref(false);

function openForgot() {
  dialogStore.openDialog(DialogIdent.SEND_FORGOT_PASSWORD_EMAIL);
}
</script>
